
/*@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');*/

* {
  box-sizing: border-box;
}

html, body {
  min-height: 100vh;
  height: auto;
  background: black;
}

body {
  overflow-y: scroll;
}

body.fullscreen,
html:fullscreen body {
  overflow: hidden;
}

.app {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: black;
  /*background: white;*/
  cursor: none;
  padding-bottom: 50px;
  position: relative;
}

body.hover .app {
  cursor: inherit; /*ça marche si quelqu'un a defini son propre curseur ?*/
}

.no-results {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  color: #d6d6d6;
  font-size: 20px;
}

.no-results .emoji {
  font-size: 35px;
  padding-right: 18px;
  vertical-align: text-top;
}

.no-results a {
  display: inline-block;
  padding: 10px;
}

.bt.close .icon {
  display: block;
  position: relative;
  width: 1px;
  height: 1px;
  overflow: visible;
}

.bt.close .icon .bar {
  position: absolute;
  display: block;
  top:0;
  left:0;
  transform: rotate(45deg);
  background: white;
}

.bt.close .icon .bar:last-child {
  transform: rotate(-45deg);
}

.disclaimer {
  position: relative;
  height: auto;
  max-height: 300px;
  border-bottom: 1px solid rgba(255,255,255,0.3);
  background: #006bbb;
}

.disclaimer.off {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s;
}

.disclaimer-inner {
  position: relative;
  padding: 13px 13px 15px;
  font-size: 13px;
  line-height: 17px;
  text-align: justify;
  color: #d9deea;
  border-top: 1px solid rgba(255,255,255,0.3);
}

.disclaimer .bt.close {
  /*display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px 39px 27px 14px;
  cursor: pointer;*/
  
  display: inline-block;
  /*padding: 7px 27px 27px 13px;*/
  padding: 16px 27px 27px 16px;
  cursor: pointer;
  float: right;
}

.disclaimer .bt.close .icon .bar {
  width: 30px;
  height: 2px;
  background: #87b9df;
}

.disclaimer .bt.close:hover .icon .bar {
  background: white;
}


.disclaimer a,
.disclaimer a:hover {
  color: #d9deea;
  text-decoration: underline;
}

.disclaimer .title {
  display: block;
  font-size: 20px;
  padding-bottom: 15px;
  line-height: 23px;
  color: white;
  /*text-align: center;*/
}

.disclaimer .title .right {
  font-style: italic;
  padding-left: 3px;
}

.disclaimer .email {
  display: inline-block;
  padding-top: 5px;
  text-decoration: none;
}

.disclaimer .email .icon {
  font-size: 10px;
  padding-right: 5px;
  text-decoration: none;
}

@media (min-width:640px) {
  .disclaimer-inner {
    /*padding: 20px 13px 23px;*/
    line-height: 19px;
  }
  
  .disclaimer .bt.close {
    padding: 12px 27px 27px 27px;
  }
  
  .disclaimer .title {
    padding-bottom: 6px;
  }
  
}

.nsfw-warning {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 15;
  text-align: center;
}

.nsfw-warning .msg {
  position: absolute;
  top: 50%;
  margin-top: -125px;
  width: 100%;
  color: white;
  text-align: center;
}

.nsfw-warning h2 {
  font-size: 30px;
  margin-bottom: 25px;
}

.nsfw-warning p {
  font-size: 23px;
  margin-bottom: 20px;
}

.nsfw-warning p.legal {
  color: red;
}

.nsfw-warning .buttons {
  margin-top: 30px;
}


.topbar {
  position: relative;
  min-height: 50px;
  z-index: 20;
  background: black;
}

.topbar-inner {
  /*position: fixed;*/
  position: relative;
  /*bottom:100%;*/
  top:0;
  left: 0;
  width: 100%;
  min-height: 50px;
  z-index: 999;
  background: black;
  color: white;
  transition: margin-top 0.1s;
  text-align: left;
}

/*.topbar .left, .topbar .right {
  position: absolute;
  display: block;
  vertical-align: top;
  top: 0;
}

.topbar .left {
  left: 0;
  right: 240px;
  text-align: left;
}

.topbar .right {
  top:0;
  right: 0;
  text-align: right;
  width: 240px;
}*/

.topbar-item {
  height: 36px !important;
  min-height: 0 !important;
  display: inline-block !important;
  vertical-align: top !important;
}

.topbar-item {
  margin-top: 6px !important;
}

.logo {
  font: 26px bold white Arial, Helvetica, sans-serif;
  /*font-family: 'Roboto';*/
  padding: 9px 5px 0 12px;
  height: 50px;
  vertical-align: top;
  display: inline-block;
}

.logo,
.logo:hover {
  color: white;
  text-decoration: none;
}

.logo:hover {
  background: #202020;
}

.app-version {
  font-size: 14px;
  color: #606060;
  vertical-align: 1px;
  font-weight: bold;
  margin-left: 3px;
}

.subreddits-container, .sort-container, .params-container {
  display: inline-block;
  position: relative;
  margin-left: 15px;
}

.sort-container,
.sort-container .text {
  text-transform: capitalize;
}

.subreddits-container {
  display: inline-block;
  position: relative;
  /*width: calc(100% - 660px);*/
  width: calc(100% - 25px);
  height: 42px;
}

.ui.search.dropdown .menu {
  max-height: none !important;
}

.topbar-item.toggle.checkbox,
.topbar-item.other-menu {
  margin-top: 0 !important;
  height: 50px !important;
}

.topbar-item.other-menu {
  padding: 0 !important;
}

.topbar-item.toggle.checkbox {
  padding: 14px 15px 8px 0;
}


.topbar-item.other-menu .menu {
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: black;
}

.topbar-item.other-menu .topbar-checkbox {
  /*padding-top: 5px;*/
  /*padding-bottom: 5px;*/
  
  padding: 16px 16px;
}

.other-menu-icon {
  display: inline-block;
  height: 50px;
  padding: 16px 6px 0;
  text-align: center;
}

.other-menu-icon:before {
  display: inline-block;
  content: "\2807";
  font-size: 23px;
}

.topbar-item.other-menu.visible .other-menu-icon {
  background: white;
  color: black;
  height: 51px;
}

.topbar-item.other-menu.visible {
  background: white;
  color: black;
}

.other-menu.ui.dropdown .menu>.item {
  padding: 0 !important;
}

.sort-container .time {
  min-width: 105px !important;
}

.sort-container .sort.topbar-item.toggle.checkbox {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sort-container .time.topbar-item.toggle.checkbox {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ui.toggle.checkbox .box, .ui.toggle.checkbox label {
  padding-left: 3em;
}

.ui.toggle.checkbox input:checked~.box:after, .ui.toggle.checkbox input:checked~label:after {
  left:1em;
}

.ui.toggle.checkbox.white .box, .ui.toggle.checkbox.white label,
.ui.toggle.checkbox.white input:checked~.box, .ui.toggle.checkbox.white input:checked~label,
.ui.toggle.checkbox.white input:focus:checked~.box, .ui.toggle.checkbox.white input:focus:checked~label {
  color: white !important;
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
  width: 2.5em;
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before,
.ui.toggle.checkbox input:focus~.box:before, .ui.toggle.checkbox input:focus~label:before,
.ui.toggle.checkbox .box:hover::before, .ui.toggle.checkbox label:hover::before {
  background: darkgray;
}

.ui.toggle.checkbox.white .box:hover::before, .ui.toggle.checkbox.white label:hover::before {
  /*background: lightgray;*/
  background: #b6b6b6;
}

.ui.toggle.checkbox .box:after, .ui.toggle.checkbox label:after {
  transition: background .1s ease,left .1s ease;
}

.ui.toggle.checkbox.white .box:after, .ui.toggle.checkbox.white label:after {
  background: white;
}

.change-consent {
  display: block;
  padding: 16px 0 10px 23px;
  font-size: 1em;
  /* text-align: right; */
}

.change-consent,
.change-consent:hover {
  color: rgba(0,0,0,.87);
}

.change-consent .icon {
  font-size: 22px;
  vertical-align: top;
  display: inline-block;
  width: 22px;
  color: darkgray;
  margin-right: 12px;
}

.subreddits-search {
  /*max-width: 200px;*/
  overflow: hidden;
  /*line-height: 30px;*/
  /*background: none;*/
}

.subreddits-search.ui.compact.selection.dropdown {
  /*min-width: 225px;*/
  /*max-width: calc(100% - 65px);*/
  /*width: auto;*/
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: calc(100% - 65px);
}

/*.subreddits-search.selection.dropdown .menu>.item:not(.addition) {*/
.subreddits-search.selection.dropdown .menu>.item:not(.option-header) {
  padding:0 10px !important;
}

.subreddits-search .item .plus.icon {
  color: rgba(0,0,0,.3);
  font-size: 25px;
  font-weight: bold;
  vertical-align: -4px;
}

.subreddits-search .menu .sublink {
  display: inline-block;
  padding: 11px 5px;
  margin-left:5px;
  color: rgba(0,0,0,.87);
}

.subreddits-search .label .sublink {
  opacity: 1;
}

.subreddits-search .label.active {
  color: rgba(0,0,0,.6);
}

.subreddits-search .label:hover,
.subreddits-search .label.active:hover,
.subreddits-search .label.active {
  background-color: #e8e8e8 !important;
}

.subreddits-search .item:hover .plus.icon,
.subreddits-search .item.selected .plus.icon {
  color: rgba(0,0,0,.7);
}
.subreddits-search .item.selected .sublink {
  color: rgba(0,0,0,.95);
}


.subreddits-search .sublink:hover {
  text-decoration: underline;
}

.subreddits-search.selection.dropdown .menu>.item.option-header {
  padding-left: 12px !important;
  cursor: default;
}

.subreddits-search .item.option-header,
.subreddits-search .item.option-header:hover,
.subreddits-search .selected.item.option-header {
  background: none !important;
}

.subreddits-search .item.option-header .text,
.subreddits-search .item:hover.option-header .text,
.subreddits-search .selected.item.option-header .text {
  display: inline-block;
  color: rgba(0,0,0,.4) !important;
  /*padding: 10px 0;*/
  border: none !important;
}

.subreddits-search.ui.compact.selection.dropdown > input.search {
  width: 3em;
}

.subreddits-container .topbar-item.subreddits-search.dropdown.selection:hover,
.topbar-item.subreddits-search.dropdown.selection.active {
  max-height: none;
  height: auto !important;
  line-height: 1em;
  overflow: visible;
  /*background: white;*/
}

.topbar-item.subreddits-submit {
  /*min-height: 37px !important;*/
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}



@media (min-width: 500px) {
  .params-container {
    margin-left: calc(100% - 465px);
  }
}


@media (max-width: 559px) {
  .subreddits-container .topbar-item {
    margin-top: 0 !important;
    margin-bottom: 4px;
  }
}

@media (min-width: 560px) {
  .subreddits-container {
    /*width: calc(100% - 395px);*/
    width: calc(100% - 190px);
  }
  
  /*.sort-container {
    !*margin-left: calc(100% - 465px);*!
    margin-left: 15px;
  }*/
}

@media (min-width: 670px) and (max-width: 1079px) {
  
  .sort-container {
    /*margin-left: calc(100% - 465px);*/
    margin-left: 180px;
  }
}

@media (min-width: 670px) {
  
  .params-container {
    /*margin-left: calc(100% - 465px);*/
    margin-left: calc(100% - 629px);
  }
}

@media (min-width: 1080px) {
  .params-container {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .subreddits-container {
    width: calc(100% - 660px);
  }
}

@media (min-width: 1280px) {
  .subreddits-container {
    width: auto;
    max-width: calc(100% - 665px);
    min-width: 500px;
  }
  
}

/*@media (max-width: 800px) {
  .subreddits-container {
    width: calc(100% - 190px);
  }
}*/


/*
@media (max-width: 1080px) {
  .subreddits-container {
    !*width: calc(100% - 395px);*!
    width: calc(100% - 190px);
  }
  
  .sort-container {
    !*margin-left: calc(100% - 465px);*!
    margin-left: 180px;
  }
  
  .params-container {
    !*margin-left: calc(100% - 465px);*!
    margin-left: calc(100% - 635px);
  }
}

!*@media (max-width: 800px) {
  .subreddits-container {
    width: calc(100% - 190px);
  }
}*!

@media (max-width: 670px) {
  
  .params-container {
    margin-left: calc(100% - 465px);
  }
  
  .sort-container {
    !*margin-left: calc(100% - 465px);*!
    margin-left: 15px;
  }
}

@media (max-width: 560px) {
  .subreddits-container {
    width: calc(100% - 25px);
  }
  
  .subreddits-container .topbar-item {
    margin-top: 0 !important;
    margin-bottom: 4px;
  }
}
*/


.flex-container {
  position: relative;
  width: 100%;
  
  /*border-right: 1px solid black;*/
  border-right: 1px solid white;
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
}

.flex-item {
  /*background: white;*/
  border-bottom: 1px solid white;
  /*border-top: 1px solid black;*/
  
  background: center center no-repeat black;
  background-size: contain;
  
  box-sizing: content-box;
}

.flex-container .flex-item {
  border-left: 1px solid white;
  /*border-left: 1px solid black;*/
}

.flex-item,
.flex-item a {
  font: 1em Arial, sans-serif;
  color: white;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* DEV */
/*.flex-item {
  border-color:red;
  !*height: auto !important;*!
}
.flex-item.PLAY {
  border-color:green;
}
.flex-item.INVISIBLE-ROW {
  border-color:white;
}*/

body.resize .flex-item {
  height: auto !important;
}

.flex-item .ratio-img {
  width: 100%;
  height: auto;
  display: block;
  visibility: hidden;
}

.flex-item, .fullscreen-container, .flex-item-media-container {
  z-index: 0;
  display: block;
  position: relative;
  text-align: center;
}

.flex-item .fullscreen-container {
  position: absolute;
  top: 0;
  left: 0;
  contain: content;
}

.flex-item.sd-loaded .fullscreen-container,
.flex-item.hd-loaded .fullscreen-container {
  position: relative;
}

.flex-item.sd-loaded .ratio-img,
.flex-item.hd-loaded .ratio-img {
  display: none;
}

.flex-item.hidden .ratio-img {
  display: block;
}

.flex-item.hidden .fullscreen-container {
  display: none;
}

/*.flex-item.min-row-item {
  height: 60vh;
}*/


.flex-item.fullscreen {
  z-index: 999;
}

.fullscreen-container, .flex-item-media-container {
   display: block;
   height: 100%;
   width: 100%;
  overflow: hidden;
}

.flex-item.fullscreen .fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  
  display: block;
  background: black;
}


.flex-item .controls-layer,
.flex-item .loading-layer,
.flex-item .loading-layer-corner,
.flex-item .loading-layer-progress,
.flex-item .loading-layer-bg,
.flex-item .loading-layer-bar,
.flex-item .bg {
   display: block;
  position: absolute;
   height: 100%;
   width: 100%;
  top: 0;
  left: 0;
}

.flex-item .controls-layer {
   display: block;
  z-index: 3;
  transition: opacity 0.2s;
  cursor: none;
}

body.hover .flex-item .controls-layer {
  opacity: 0.5;
  transition: opacity 0.2s;
  cursor: pointer;
}

body.hover .flex-item:hover .controls-layer {
  opacity: 1;
  transition: opacity 0.2s;
}

.flex-item .controls-layer,
body.hover .flex-item.clicked .controls-layer,
body.hover .flex-item.play-started .controls-layer {
  opacity: 0;
}

.flex-item.fullscreen .fullscreen-container .controls-layer {
  display: none;
}


.flex-item .bt.floating {
  display: block;
  position: absolute;
}
.flex-item .bt .icon {
  display: inline-block;
  position: relative;
}

.item-loading-spinner {
  display: none;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  width: 40px;
  height: 40px;
  border: 2px solid white;
  border-color: transparent rgba(255,255,255,0.3);
  border-radius: 50%;
  animation: 2.5s linear 0s infinite running loading-spin;
  z-index: 999;
}


.flex-item.fullscreen .item-loading-spinner {
  display: none !important;
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
}

.flex-item .controls-layer .bt.play {
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  margin: -25px 0 0 -14px;
  border: 25px solid transparent;
  border-right: none;
  border-left: 38px solid white;
}



.flex-item .bt.fullscreen-control {
  display: none;
  opacity: 0;
  transition: opacity 0.5s;
  text-decoration: none;
  cursor: none;
  /*background: red; !* TEST *!*/
}

.flex-item .top-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  text-align: left;
}

.flex-item .top-controls .left,
.flex-item .top-controls .right {
  position: absolute;
  top: 0;
}

.flex-item .top-controls .left {
  left: 0;
  text-align: left;
}

.flex-item .top-controls .right {
  right: 0;
  text-align: right;
}


.flex-item .top-controls .bt.fullscreen-control {
  display: none;
  height: 100px;
  position: relative;
}

.flex-item.fullscreen .top-controls .bt.fullscreen-control {
  display: inline-block;
  vertical-align: top;
}

.flex-item .top-controls .bt.fullscreen-control .out.icon {
  display: inline-block;
  width: 12px;
  margin: 0 0 0 5px;
  vertical-align: initial;
  opacity: 0;
  transition: opacity 0.2s;
}

.flex-item .bt.fullscreen-control.title .link:hover .out.icon {
  opacity: 1;
  transition: opacity 0.2s;
}

.flex-item .top-controls .top-controls .bt.source:hover .out.icon {
  opacity: 1;
  transition: opacity 0.2s;
}

.flex-item.fullscreen .top-controls .bt.source .icon {
  vertical-align: middle;
}

.flex-item.fullscreen .top-controls .bt.source .source.icon {
  max-height: 26px;
  max-width: 60px;
}

.flex-item.fullscreen .top-controls .bt.download {
  padding: 6px 7px 0 5px;
}

.flex-item.fullscreen .top-controls .bt.share {
  padding: 6px 7px 0;
}


.flex-item.fullscreen .top-controls .bt.download .icon {
  width:25px;
}

.flex-item.fullscreen .bt.nav {
  display: block;
}

body.hover .flex-item.fullscreen .bt.fullscreen-control {
  opacity: 0.25;
  cursor: pointer;
  transition: opacity 0.2s;
}


body.hover .flex-item.fullscreen .bt.fullscreen-control:active,
body.hover .flex-item.fullscreen .bt.fullscreen-control:hover {
  opacity: 1;
}

.flex-item .bt.fullscreen-control.title {
  display: inline-block;
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  line-height: 1.4em;
  letter-spacing: 0.005em;
  cursor: default;
  padding: 9px 10px 8px 2px;
  background: rgba(0,0,0,0.7);
  opacity: 0;
  transition: opacity 1s;
}

.flex-item .bt.fullscreen-control.title .sublink {
  display: inline-block;
  opacity: 0.5;
  margin: 0 10px 0 0;
  transition: opacity 0.2s;
}

.flex-item .bt.fullscreen-control.title .sublink .subname {
  padding-left: 3px;
}

.flex-item .bt.fullscreen-control.title .sublink:hover {
  opacity: 1;
}

.flex-item .bt.fullscreen-control.title a {
  text-decoration: none;
}

.flex-item .bt.fullscreen-control.title a:hover {
  text-decoration: underline;
}

.flex-item.fullscreen .bt.fullscreen-control.title {
  margin: 0;
  padding: 11px 175px 13px 265px;
  height: auto;
  text-align: center;
}

.flex-item .bt.fullscreen-control.title .reddit.icon {
  height: 16px;
  vertical-align: top;
  margin-right: 3px;
  opacity: 0;
  transition: opacity 0.2s;
}

.flex-item .bt.fullscreen-control.title:hover .reddit.icon,
.flex-item.fullscreen .bt.fullscreen-control.title .reddit.icon {
  opacity: 1;
  transition: opacity 0.2s;
}

.flex-item.fullscreen .bt.fullscreen-control.title .reddit.icon {
  margin-right: 10px;
}

body.titles .flex-item.fullscreen .bt.fullscreen-control.title,
body.title .flex-item.fullscreen .bt.fullscreen-control.title,

body.titles.allow-titles .flex-item .bt.fullscreen-control.title,
body.title .flex-item:hover .bt.fullscreen-control.title,
body.title-link .flex-item .bt.fullscreen-control.title:hover
{
  opacity: 1;
  transition: opacity 0.2s;
}


.flex-item .bt.source {
  padding: 10px 0 0 7px;
}

.flex-item .bt.nav {
  top: 50%;
  margin-top: -100px; /*pos y 476*/
  width: 185px;
  z-index: 2;
}

.flex-item .bt.nav.prev {
  padding: 90px 0 90px 75px;
  text-align: left;
  left: 0;
}

.flex-item .bt.nav.next {
  padding: 90px 75px 90px 0;
  text-align: right;
  right: 0;
}


.flex-item .bt.nav .icon {
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  border-top: 3px solid white;
  border-right: 3px solid white;
}

.flex-item .bt.nav.prev .icon {
  transform: rotate(-135deg);
}


.flex-item .top-controls .bt.fullscreen-control.close {
  padding: 8px 85px 0 90px;
}

.flex-item .bt.res {
  padding: 3px 0 0 60px;
  font-size: 16px;
  user-select: none;
}

.flex-item .bt.res .inner,
.flex-item .bt.res .size {
  position: relative;
  display: block;
}

.flex-item .bt.res .inner {
  overflow: hidden;
  height: 36px;
}

.flex-item.multi-size .bt.res .inner {
  background: rgba(0,0,0,0.3);
}

.flex-item.only .bt.res .inner {
  background: rgba(0,0,0,0.1);
}

.flex-item.only.sd-loaded .bt.res .inner,
.flex-item.only.hd-loaded .bt.res .inner {
  background: none;
}

.flex-item .bt.res .loading-bar {
  position: absolute;
  top:0;
  left: 0;
  margin-left: -100%;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 0;
}


.flex-item .bt.res .size {
  padding: 10px;
  z-index: 1;
  line-height: 1em;
}

body.hover .flex-item.only .bt.res {
  cursor: default;
}

body.hover .flex-item.only .bt.res:active,
body.hover .flex-item.only .bt.res:hover {
  opacity: 0.25;
}

.flex-item.multi-size .bt.res:active .inner,
.flex-item.multi-size .bt.res:hover .inner {
  background: rgba(0,0,0,0.15);
}
.flex-item .bt.res:active .loading-bar,
.flex-item .bt.res:hover .loading-bar {
  background: rgba(0,0,0,0.3);
}

.flex-item.hd-on .bt.res .sd,
.flex-item .bt.res .hd {
  display: none;
}

.flex-item.hd-on.hd-loaded .bt.res .loading-bar.hd,
.flex-item.sd-loaded .bt.res .loading-bar.sd {
  display: none;
}

.flex-item.hd-on .bt.res .hd {
  display: block;
}

.flex-item .bt.close .icon .bar {
  width: 30px;
  height: 3px;
}


.flex-item .loading-layer-bg,
.flex-item .loading-layer-bar,
.flex-item .bg {
  background-color: black;
  /*background-color: white;*/
  /*background: white !important; !* TEST *!*/
}


.flex-item .loading-layer {
  z-index: 3;
}

.flex-item .loading-layer-bg {
  opacity: 0.05;
}


.flex-item .loading-layer-bar {
  top:0;
  left: -100%;
  height: 2px;
  background-color: white;
  opacity: 1;
}

.flex-item .bg {
  display: none;
  z-index: 0;
  /*top:-25px;
  left: -25px;
  width: calc(100% + 50px);
  height: calc(100% + 50px);*/
  
  background: 50% 25% no-repeat black;
  background-size: cover;
  
  /*will-change: filter;*/
  /*display: block !important; !*TEST*!*/
  /*filter: none; !*TEST*!*/
  /*opacity: 1; !*TEST*!*/
}

.flex-item.item-row .bg,
.flex-item.fullscreen .bg {
  display: block;
  opacity: 0.3;
  filter: blur(20px);
  /*transform-origin: center center;*/
  /*transform: scale(1.05);*/
}

.flex-item.play-started .loading-layer-bg {
  opacity: 0.02;
}

.flex-item.sd-loaded .loading-layer,
.flex-item/*.hd.only*/.hd-loaded .loading-layer,
/*.flex-item .fullscreen-container:fullscreen .loading-layer {*/
.flex-item.fullscreen .fullscreen-container .loading-layer {
  display: none;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.media {
  position: relative;
  vertical-align: middle;
  background: transparent;
}

.media.video {
  z-index: 1;
  height: 100%;
  width: 100%;
  display: inline-block;
  /*visibility: hidden !important; !* TEST *!*/
}

.media.img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
  /*visibility: hidden !important; !* TEST *!*/
}

.flex-item.item-row.vertical .media.img {
  width: auto;
  height: 100%;
}


.flex-item .img-container {
  background: no-repeat center center transparent;
  /*background-color: transparent;*/
  background-size: contain;
  /*background-position: center center;*/
  /*background-repeat: no-repeat;*/
  position:relative;
  width: 100%;
  height: 100%;
}

.flex-item:not(.fullscreen) .img-container {
  background-image: none !important;
}

.flex-item.fullscreen .media.img {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.media.video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.media.video::-webkit-media-controls-play-button,
.media.video::-webkit-media-controls-current-time-display,
.media.video::-webkit-media-controls-time-remaining-display,
.media.video::-webkit-media-controls-volume-control-container {
  order: -1;
}

.media.video::-webkit-media-controls-volume-control-container {
  margin-left: 15px;
}


.flex-item .hd-container {
  position: relative;
  text-align: center;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.flex-item.multi-size .hd-container {
  position: absolute;
  top:0;
  left: 0;
  z-index: 0;
}

.flex-item.hd-on .hd-container {
  z-index: 2;
  opacity: 1;
  transition: opacity 0.2s;
}

.video:focus {
  outline: none;
}

.loading-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.loading-footer .bt-load, .loading-footer .animation {
  width: 100%;
  text-align: center;
  color: white;
  -webkit-user-select: none;
  user-select: none;
  height: 50px;
}


.loading-footer .bt-load {
  display: block;
  text-align: center;
  color: white;
  padding: 18px 20px 10px;
  -webkit-user-select: none;
  user-select: none;
  background: #282828;
  opacity: 0.5;
}

.loading-footer .bt-load:hover {
  opacity: 1;
}

.loading-footer .animation {
  position: fixed;
  display: none;
  bottom: 0;
  left: 0;
  padding: 16px 20px 10px;
  background: rgba(0,0,0,0.5);
  z-index: 999;
}

.loading-footer.on .animation {
  display: block;
}

.loading-footer.on .bt-load {
  display: none !important;
}

.loading-footer .anim,
.loading-footer .anim .invisible,
.loading-footer .anim .inner,
.loading-footer .emoji,
.loading-footer .emoji .container,
.loading-footer .emoji .inner {
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  text-align: left;
  position: relative;
}

.loading-footer .emoji {
  overflow: hidden;
  margin-right: 2px;
}

.loading-footer .emoji .container,
.loading-footer .emoji .container .track {
  position: absolute;
  width:420px;
  height: 21px;
  top: 0;
  left: 0;
}

.loading-footer .emoji .container .track {
  display: none;
}

.loading-footer .emoji .container.e0 .track:nth-child(1),
.loading-footer .emoji .container.e1 .track:nth-child(2),
.loading-footer .emoji .container.e2 .track:nth-child(3),
.loading-footer .emoji .container.e3 .track:nth-child(4),
.loading-footer .emoji .container.e4 .track:nth-child(5),
.loading-footer .emoji .container.e5 .track:nth-child(6),
.loading-footer .emoji .container.e6 .track:nth-child(7),
.loading-footer .emoji .container.e7 .track:nth-child(8),
.loading-footer .emoji .container.e8 .track:nth-child(9),
.loading-footer .emoji .container.e9 .track:nth-child(10) {
  display: inline-block;
}

.loading-footer .emoji,
.loading-footer .emoji .inner {
  width: 21px;
  height: 21px;
}

.loading-footer .anim .invisible {
  visibility: hidden;
}

.loading-footer .anim .inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.loading-footer .anim .inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  white-space: nowrap;
  animation: 0.7s linear 0s infinite running loading-slide;
}

@keyframes loading-slide {
  from {
    width: 0;
  }
  
  to {
    width: 150%;
  }
}



@media (max-width: 1100px) {
  .flex-item .top-controls .bt.fullscreen-control {
    height: 46px;
  }
  
  .flex-item .bt.fullscreen-control.title {
    height: auto;
  }
  
  .flex-item.fullscreen .bt.fullscreen-control.title {
    padding-left:205px;
    padding-right:125px;
  }
  
  .flex-item .bt.res {
    padding-left: 4px;
  }
  
  .flex-item .top-controls .bt.fullscreen-control.close {
    padding-right: 35px;
  }
  
  .flex-item .bt.nav.prev {
    padding-left: 20px;
  }
  
  .flex-item .bt.nav.next {
    padding-right: 20px;
  }
}

@media (max-width: 900px) {
  .flex-item.fullscreen .bt.fullscreen-control.title {
    padding-right:75px;
  }
  
  .flex-item .top-controls .bt.fullscreen-control.close {
    padding-left: 40px;
  }
}

@media (max-width: 650px) {
  .flex-item.item-row .bg,
  .flex-item.fullscreen .bg {
    filter: none;
  }
  
  .flex-item.fullscreen .bt.fullscreen-control.title {
    /*padding: 50px 10px 10px;*/
    padding: 10px 10px 8px;
    height: auto;
  }
  
  body.titles .flex-item.fullscreen .bt.fullscreen-control.title {
    background-color: rgba(0,0,0,0.7);
    transition: background-color 0.5s;
  }
  
  body.hover.titles .flex-item.fullscreen .bt.fullscreen-control.title,
  body.hover .flex-item.fullscreen .bt.fullscreen-control.title {
    background-color: rgba(0,0,0,0);
    /*transition: background-color 0.2s;*/
  }
  
  .flex-item.fullscreen .top-controls {
    background: rgba(0,0,0,0);
    transition: background-color 0.5s;
  }
  
  body.hover .flex-item.fullscreen .top-controls {
    background-color: rgba(0,0,0,0.7);
    transition: background-color 0.2s;
  }
  
  .flex-item .top-controls .left,
  .flex-item .top-controls .right {
    position: relative;
    /*top: 0;*/
  }
  .flex-item .top-controls .left {
    float: left;
  }
  .flex-item .top-controls .right {
    float: right;
  }
}

@media (max-width: 370px) {
  .flex-item .bt.nav {
    width: 50%;
  }
}